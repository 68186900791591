import React, { memo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import Avatar from '@mui/material/Avatar'
import sx from 'styles/globalStyles'

const ReviewsAndPressSkeleton = ({ variant = 'row', showPress }) => {
  return (
    <Box>
      <Container sx={{ ...sx.googleReviewsContainer, paddingBottom: 4, marginBottom: 3 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={variant === 'column' ? 12 : 4}
            sx={{ padding: 1 }}
          >
            {[100, 40].map((grTitlePercent, i) => (
              <Skeleton
                key={`grTitlePercent-${i}`}
                variant="rectangular"
                height={20}
                width={`${grTitlePercent}%`}
                animation="wave"
                sx={{ marginBottom: i === 0 ? 1 : 0 }}
              />
            ))}
            {[100, 100].map((descPercent, index) => (
              <Skeleton
                key={`descPercent-${index}`}
                variant="rectangular"
                height={10}
                width={`${descPercent}%`}
                animation="wave"
                sx={{ marginTop: index === 0 ? 2 : 1 }}
              />
            ))}
            <Skeleton
              variant="rectangular"
              width="100%"
              sx={{ marginTop: 2 }}
            >
              <div style={{ paddingTop: 150 }} />
            </Skeleton>

          </Grid>

          <Grid
            item
            xs={12}
            lg={variant === 'column' ? 12 : 4}
            sx={{ paddingX: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ margin: 1 }}>
                <Skeleton
                  variant="circular"
                  animation="wave"
                >
                  <Avatar />
                </Skeleton>
              </Box>
              <Box sx={{ width: '100%' }}>
                {[100, 20].map((reviewPercent, itemIndex) => (
                  <Skeleton
                    key={`reviewPercent-${itemIndex}`}
                    variant="rectangular"
                    width={`${reviewPercent}%`}
                    height={10}
                    animation="wave"
                    sx={{ marginBottom: itemIndex === 0 ? 1 : 0 }}
                  />
                ))}
              </Box>
            </Box>
            <Skeleton
              variant="rectangular"
              width="20%"
              height={10}
              animation="wave"
              sx={{ margin: 1, marginBottom: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"

            >
              <div style={{ paddingTop: 175 }} />
            </Skeleton>
          </Grid>

          {showPress && (
            <Grid
              item
              xs={12}
              lg={variant === 'column' ? 12 : 4}
              sx={{ padding: 2 }}
              alignItems="center"
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height={10}
                animation="wave"
                sx={{ marginBottom: 2 }}
              />
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
              >
                {[0, 1, 2, 3].map((_, pressIndex) => (
                  <Box
                    sx={{ width: '49%', padding: 1 }}
                    key={`press-items-skeleton-${pressIndex}`}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={60}
                      animation="wave"
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default memo(ReviewsAndPressSkeleton)
